@import url(//fonts.googleapis.com/css?family=Open+Sans);

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  text-align: left;
}

html {
  height: 100%;
}

#root {
  min-height: 100%; 
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-submenu-title:hover {
  background: "#3957ED";
}

.backgroundWhite {
  background-color: #ffffff;
}

.header {
  display: flex;
  background-color: #ffffff;
}

.ant-menu-item-active {
  color: "#3957ED" important;
  border-bottom: 2px solid "#3957ED" important;
}

.logo_container {
  flex: 2;
}

.menu_container {
  flex: 1;
}

.footerContainer {
  overflow: hidden;
}

.footerContainer div {
  position: relative;
}

.footer:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 120%;
  transform: skew(0deg, -3deg);
  background: lightgray;
}

.offerContainer {
  position: relative;
}

.offerContainer h1 {
  z-index: 1;
}

.offerContainer:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: skew(0deg, -3deg);
  background: linear-gradient(104.02deg, #ACB6E5 6.96%, #86FDE8 91.91%);
  transform-origin: right;
}

.overviewContainer {
  max-width: 100%;
}

.lightPinkGradient {
  position: relative;
  max-width: 100%;
}

.lightPinkGradient:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: skew(0deg, -3deg);
  background: linear-gradient(104.02deg, rgba(255, 110, 127, 0.3) 6.96%, rgba(191, 233, 255, 0.3) 91.91%);
  transform-origin: right;
}

.lightPurpleGradient {
  position: relative;
  max-width: 100%;
}

.lightPurpleGradient:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: skew(0deg, -3deg);
  background: linear-gradient(104.02deg, rgba(151, 150, 240, 0.3) 6.96%, rgba(251, 199, 212, 0.3) 91.91%);
  transform-origin: right;
}

.servicesContainer {
  background: linear-gradient(104.02deg, rgba(54, 209, 220, 0.102) 6.96%, rgba(58, 109, 221, 0.072) 91.91%);
  transform: skew(0deg, -3deg);
  margin-top: 5rem;
}

.servicesContent{
  transform: skew(0deg, 3deg);
}

.footer_section_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_section_title {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}

.footer_section_items {
  display: flex;
  flex-direction: column;
}

.footer_section_item {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.tag_line {
  font-weight: 800;
  font-size: 58px;
  line-height: 80px;
  color: #ffffff;
}

.section_title {
  color: #412673;
  font-weight: bold;
  font-size: 38px;
  line-height: 22px;
  border-bottom-color: rgba(250, 177, 39, 0.8);
  border-bottom-width: 12px;
  border-bottom-style: solid;
}

.height60vh {
  height: 60vh;
}

.services_container {
  overflow-x: scroll;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.service_item {
  padding: 24px 40px;
  border-color: #646464;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}

.home_service_item {
  border-radius: 10px;
  background-color: #ffffff;
}

.service_item_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  color: black;
  margin-top: 24px;
  margin-bottom: 24px;
}

.service_item_desc {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

.enquiry_section {
  height: 70vh;
}

.page_title {
  font-weight: bold;
  font-size: 38px;
  line-height: 22px;
  color: #412673;
}

.page_subtitle {
  font-weight: 600;
  font-size: 26px;
  line-height: 22px;
  color: #3957ed;
}

.insurance_container {
  width: "50%";
  margin: auto;
}

.insurance_item_container {
  padding: 32px;
  border: 1px solid #0f0e0e;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0f0e0e;
  text-align: center;
}

.divider {
  border-bottom-color: black;
  border-bottom-width: "100%";
  border-bottom-style: solid;
}

.cursorPointer {
  cursor: pointer;
}

.success_dialog_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #412673;
  text-align: center;
}

.success_dialog_subtext {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: rgba(15, 14, 14, 0.5);
}

.success_button {
  width: 50%;
  background-color: #3957ed;
  color: #ffffff;
}

.upload_subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
}

.modalContent {
  max-height: 50vh;
  overflow-y: scroll;
}

.banner_container {
  height: 30vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.appWrapper {
  display: flex;
  min-height: 100vh;
}

.backgroundGrey {
  background-color: #F9F9F9;
}

.wordBreak {
  word-break: break-all;
}

.boxShadowLight {
  box-shadow: 0px 4px 24px 10px rgba(160, 170, 182, 0.1);
}

.react-multi-carousel-item {
  min-width: 33vw;
  max-height: 200px;
}

.react-multi-carousel-item img {
  max-height: 200px;
}

.react-multi-carousel-item--active :nth-of-type(2) {
  min-width: 33vw;
  max-height: 250px;
}

.react-multi-carousel-item--active :nth-of-type(2) img {
  max-height: 250px;
}

.bikeCarosel {
  transform: skew(0deg, -3deg);
  background: linear-gradient(104.02deg, rgba(255, 110, 127, 0.3) 6.96%, rgba(191, 233, 255, 0.3) 91.91%);
  transform-origin: right;
}

.bikeCaroselContent {
  transform: skew(0deg, 3deg);
}

.scooterCarosel {
  transform: skew(0deg, -3deg);
  background: linear-gradient(104.02deg, rgba(151, 150, 240, 0.3) 6.96%, rgba(251, 199, 212, 0.3) 91.91%);
  transform-origin: right;
}

.scooterCaroselContent {
  transform: skew(0deg, 3deg);
}